<template>
  <div class="container">
    <div class="product">
      <div class="title">
        {{ this.product.title }}
      </div>

      <div class="form-group mt-2">
        <label class="mb-1">Название</label>
        <input type="text" class="form-control" v-model="item.title"/>
      </div>

      <div class="form-group mt-2">
        <label class="mb-1">Полное описание</label>
        <quill-editor v-model="item.description"/>
      </div>

      <div class="form-group mt-2">
        <label class="mb-1">Тип товара в системе OZON</label>
        <select class="form-select" v-model="item.ozon_type_id" v-on:change="onChangeOzonType">
          <optgroup v-for="group in this.ozonCategories" :label="group.name" v-bind:key="group.id">
            <option v-for="option in group.children" :value="option.id" v-bind:key="option.id">
              {{ option.name }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="form-group mt-2">
        <label class="mb-1">Порядок товара</label>
        <input class="form-control" type="number" @change="editSort(item.id, $event.target.value)" placeholder="Порядок"
               :value="item.sort">
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              Цены товара
            </div>
            <div class="card-body">
              <div class="row" v-if="item.productsAvailables">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].price">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Старая цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].old_price">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Акционная цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].normal_old_price">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Яндекс цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].yandex_price">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              Свойства товара
            </div>
            <div class="card-body">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-1" :checked="parseInt(item.freeze)"
                       @change="changeProperty('freeze')">
                <label class="form-check-label" for="property-1">Свежемороженный</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-2" :checked="parseInt(item.new)"
                       @change="changeProperty('new')">
                <label class="form-check-label" for="property-2">Новинка</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-3" :checked="parseInt(item.week)"
                       @change="changeProperty('week')">
                <label class="form-check-label" for="property-3">Товар недели</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-4" :checked="parseInt(item.preparation)"
                       @change="changeProperty('preparation')">
                <label class="form-check-label" for="property-4">Заготовка</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-5" :checked="parseInt(item.grain)"
                       @change="changeProperty('grain')">
                <label class="form-check-label" for="property-5">Зерновой</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-6" :checked="parseInt(item.seafood)"
                       @change="changeProperty('seafood')">
                <label class="form-check-label" for="property-6">Живые морепродукты</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex align-items-center pointer" @click="openCardBody('image')">
              Фотография
              <i class="fas fa-chevron-down ms-auto"
                 :style="{ transform: visibleCardBody.ozon ? 'rotate(180deg)' : 'rotate(0deg)' }"></i>
            </div>
            <div class="card-body" v-if="visibleCardBody.image">
              <div class="upload-image">
                <img :src="appImageUrl + item.eco_medium_image" v-if="item.eco_medium_image" alt=""/>
                <img :src="appImageUrl + item.eco_big_image" v-else alt=""/>
              </div>

              <div class="form-group mt-2">
                <label class="form-label">Фото</label>
                <input class="form-control" type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
              </div>
            </div>
            <div class="card-footer d-flex" v-if="visibleCardBody.image">
              <button class="btn btn-danger" v-on:click="deleteImage()">Удалить фотографии товара</button>
              <button class="btn btn-success ms-auto" v-on:click="submitFile()">Загрузить</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex align-items-center pointer" @click="openCardBody('category')">
              Категории
              <i class="fas fa-chevron-down ms-auto"
                 :style="{ transform: visibleCardBody.category ? 'rotate(180deg)' : 'rotate(0deg)' }"></i>
            </div>
            <div class="card-body" v-if="visibleCardBody.category && categories">
              <div class="product-category-path" :key="componentKey">
                <tree
                    :data="categories"
                    :options="options"
                    ref="tree"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex align-items-center pointer" @click="openCardBody('ozon')">
              Атрибуты OZON
              <i class="fas fa-chevron-down ms-auto"
                 :style="{ transform: visibleCardBody.ozon ? 'rotate(180deg)' : 'rotate(0deg)' }"></i>
            </div>
            <div class="card-body" v-if="visibleCardBody.ozon">
              <div class="form-group mt-3" v-for="attribute in ozonAttributes" v-bind:key="attribute.id">
                <label class="mb-1">{{ attribute.name }}</label>
                <input type="text" class="form-control" v-if="attribute.field_type === 'input'"
                       v-model="selectAttributes[attribute.id]"/>

                <multiselect v-if="attribute.field_type === 'select'" v-model="selectAttributes[attribute.id]"
                             :options="attribute.values" placeholder="Выберите" label="value"
                             track-by="id"></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-success mt-4 mb-3" type="button" v-on:click="saveProduct" style="float:right;">Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: 'view-product',
  data: function () {
    return {
      appImageUrl: process.env.VUE_APP_IMAGE_URL,
      id: this.$route.params.id,
      item: [],
      file: null,
      image: null,
      options: {
        checkbox: true
      },
      test: null,
      attributes: [],
      selectAttributes: [],
      componentKey: 0,
      visibleCardBody: {
        image: false,
        ozon: false,
        category: false,
      }
    }
  },
  computed: {
    ...mapGetters(['product', 'ozonCategories', 'ozonAttributes', 'ozonAttributeSelectedValues']),
    categories() {
      return this.item?.cats
          ? Object.entries(this.item.cats).map(entry => entry[1])
          : [];
    }
  },
  beforeUpdate() {
    this.item = this.product;
    this.selectAttributes = this.ozonAttributeSelectedValues;

    this.$nextTick(() => {
      this.componentKey = 1;
    });
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    onChangeOzonType: function () {
      let self = this;
      setTimeout(function () {
        self.getOzonAttributes();
        self.getOzonAttributeSelectedValuesValues()
      }, 1000);
    },
    deleteImage: function () {
      this.$store.dispatch('deleteImageProduct', {'id': this.id});
    },
    changeProperty: function (value) {
      this.$store.dispatch('changeProperty', {'id': this.id, 'property': value});
    },
    getProduct: function () {
      this.$store.dispatch('getProduct', this.id);
    },
    async getOzonCategories() {
      await this.$store.dispatch('getOzonCategories');
    },
    async getOzonAttributes() {
      if (this.item.ozon_type_id !== undefined) {
        let self = this;
        let category = this.ozonCategories.filter((group) => group.children.filter((item) => item.id == self.item.ozon_type_id).length > 0)
        await this.$store.dispatch('getOzonAttributes', {
          categoryId: category[0].id,
          typeId: this.item.ozon_type_id,
          productId: this.item.id,
        });
      }
    },
    async getOzonAttributeSelectedValuesValues() {
      await this.$store.dispatch('getOzonAttributeSelectedValues', this.id);
    },
    saveProduct: function () {
      let tree = this.$refs.tree.findAll({state: {checked: true}});
      this.item.selectCats = tree.map(function (item) {
        return item.id;
      }).join(",");

      this.$store.dispatch('saveProduct', this.item);
      let self = this;
      let category = this.ozonCategories.filter((group) => group.children.filter((item) => item.id == self.item.ozon_type_id).length > 0)
      let form = {
        itemId: this.item.id,
        typeId: this.item.ozon_type_id,
        categoryId: category[0].id,
        attributes: []
      };

      Object.keys(this.selectAttributes).forEach(function (key) {
        form.attributes.push({key: key, value: self.selectAttributes[key]})
      });


      this.$store.dispatch('saveOzonAttributesProduct', form);
    },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('id', this.item.id);

      this.$store.dispatch('uploadImage', formData).then(() => {
        this.file = null
      })
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    editSort: function (id, value) {
      this.$store.dispatch('editSortProduct', {'id': id, 'sort': value});
    },
    async openCardBody(value) {
      switch (value) {
        case 'ozon':
          if (!this.visibleCardBody.ozon) {
            await this.getOzonCategories();
            await this.getOzonAttributes();
            await this.getOzonAttributeSelectedValuesValues()
            this.visibleCardBody.ozon = true
          } else {
            this.visibleCardBody.ozon = false
          }
          break;
        default:
          this.visibleCardBody[value] = !this.visibleCardBody[value]
      }
    }
  },
  components: {
    Multiselect
  }
}
</script>

<style lang="scss" scoped>
.product {
  padding: 20px 0 20px 0;

  .product-category-path {
    display: flex;
    justify-content: flex-start;
  }

  .upload-image {
    width: 250px;
    height: 250px;
    margin-inline: auto;

    img {
      width: inherit;
      height: inherit;
    }
  }
}
</style>
